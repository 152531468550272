
import { useCarWash } from '@/composables/useCarWash'
import InputAmount from '@/components/InputAmount.vue'
import { defineComponent, ref, onMounted, computed } from 'vue'
import { ICarWashStockItem } from '@/interfaces/ICarWash'
import { useRoute, useRouter } from 'vue-router'
import localforage from 'localforage'
import { useDelivery } from '@/composables/useDelivery'

export default defineComponent({
    components: {
        InputAmount,
    },
    setup() {
        const { activeCarWashStock, fetchCarWashStock } = useCarWash()
        const {
            isLoading,
            currentDelivery,
            fetchSavedDeliveryReturns,
            removeSavedDeliveryReturns,
            fetchDelivery,
        } = useDelivery()
        const route = useRoute()
        const router = useRouter()
        const productsToAdd = ref<ICarWashStockItem[]>([])
        const initialCarWashStockProducts = ref<ICarWashStockItem[]>([])
        const isSubmitButtonDisabled = ref(true)

        const hasContainers = computed(() =>
            productsToAdd.value.find((elem: ICarWashStockItem) => elem.isContainer),
        )

        const blockSubmitButton = async () => {
            if (!hasContainers.value || !productsToAdd.value) {
                isSubmitButtonDisabled.value = true
            } else {
                isSubmitButtonDisabled.value = false
            }
        }

        const addProductAmount = (product: ICarWashStockItem, quantity: number) => {
            // Check if quantity is bigger than 0 before pushing
            if (!productsToAdd.value.find(elem => elem.id === product.id)) {
                productsToAdd.value.push(product)
            } else {
                // if object is in the array - change it's quantity
                const objectToChange = productsToAdd.value.find(elem => elem.id === product.id)
                if (objectToChange) {
                    objectToChange.quantity = quantity
                    // if object quantity is 0 - remove object from array
                    if (objectToChange.quantity === 0) {
                        const objectToChangeIndex = productsToAdd.value.findIndex(
                            elem => elem === objectToChange,
                        )
                        productsToAdd.value.splice(objectToChangeIndex, 1)
                    }
                }
            }
        }

        const saveDeliveryReturns = async () => {
            const deliveryReturnsObject = {
                deliveryId: route.params.id,
                carWashId: route.params.carWashId,
                products: JSON.parse(JSON.stringify(productsToAdd.value)),
            }
            const savedReturns = await localforage.setItem('deliveryReturns', deliveryReturnsObject)
            if (savedReturns) {
                router.push({ name: 'DeliveryDetailsCourier', params: { id: route.params.id } })
            }
        }
        const getInitalProductQuantity = (product: ICarWashStockItem) => {
            return initialCarWashStockProducts.value.find(item => item.id === product.id)?.quantity
        }

        onMounted(async () => {
            await fetchDelivery(+route.params.id)
            await fetchCarWashStock(currentDelivery.value?.destinationWarehouseDetails[0].id)
            // Save inital stock without reactivity for reset
            initialCarWashStockProducts.value = JSON.parse(
                JSON.stringify(activeCarWashStock.value.products),
            )
            if (activeCarWashStock.value.products) {
                productsToAdd.value = activeCarWashStock.value.products.filter(
                    product => product.isContainer,
                )
            }
            const savedReturns = await fetchSavedDeliveryReturns()
            if (savedReturns) {
                savedReturns.products.forEach((product: ICarWashStockItem) => {
                    const itemToUpdate = activeCarWashStock.value.products.find(
                        item => item.id === product.id,
                    )
                    if (itemToUpdate) {
                        itemToUpdate.quantity = product.quantity
                    }
                })
            }
            blockSubmitButton()
        })

        return {
            router,
            route,
            activeCarWashStock,
            addProductAmount,
            saveDeliveryReturns,
            isLoading,
            currentDelivery,
            isSubmitButtonDisabled,
            removeSavedDeliveryReturns,
            productsToAdd,
            blockSubmitButton,
            getInitalProductQuantity,
        }
    },
})
